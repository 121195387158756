import { useCallback, useEffect, useRef } from 'react';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
const useGetDropPosition = _ref => {
  let {
    activeTileIndex,
    setActiveDropIndex,
    catalogElementRef
  } = _ref;
  const previousBreakpointRef = useRef(Breakpoint.L);
  const breakpoint = useBreakpoint(Breakpoint.L);
  const getDropIndexForTile = useCallback(() => {
    if (!catalogElementRef.current) {
      return -1;
    }
    const tiles = [...catalogElementRef.current.querySelectorAll('[data-rainbow-catalog-tile]')];
    const currentTile = tiles[activeTileIndex];
    if (!currentTile) {
      return -1;
    }
    const tilesAfterActive = tiles.slice(activeTileIndex);
    const filtered = tilesAfterActive.filter(tile => tile.offsetTop === currentTile.offsetTop);
    return tiles.indexOf(filtered[filtered.length - 1]);
  }, [activeTileIndex, catalogElementRef]);
  useEffect(() => {
    if (!catalogElementRef.current) {
      return;
    }
    // изменение тригерится ресайзом экрана, для правильного подсчета позиции надо сначала скрыть drop
    if (previousBreakpointRef.current !== breakpoint) {
      const activeDrop = catalogElementRef.current.querySelector('[data-rainbow-catalog-drop-active="true"]');
      activeDrop === null || activeDrop === void 0 ? void 0 : activeDrop.classList.add('g-hidden');
      previousBreakpointRef.current = breakpoint;
      setActiveDropIndex(getDropIndexForTile());
      activeDrop === null || activeDrop === void 0 ? void 0 : activeDrop.classList.remove('g-hidden');
    } else {
      setActiveDropIndex(getDropIndexForTile());
    }
  }, [breakpoint, catalogElementRef, getDropIndexForTile, setActiveDropIndex]);
};
export default useGetDropPosition;