const _excluded = ["vacancies"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import fetcher from 'src/utils/fetcher';

// tempexp_clickme-vacancy-of-the-day-experiment_start
import { EMPTY_ARRAY, MAX_VOD_VACANCIES_COUNT } from 'src/components/RainbowCatalog/const';
import getClickmeVacancies from 'src/components/RainbowCatalog/getClickmeVacancies';
import shuffleVacanciesOfTheDayWithClickme, { calcFirstFakeVacancyIndex } from 'src/components/RainbowCatalog/shuffleVacanciesOfTheDayWithClickme';
// tempexp_clickme-vacancy-of-the-day-experiment_end

const vacanciesOfTheDayAction = makeSetStoreField('vacanciesOfTheDay');

// tempexp_clickme-vacancy-of-the-day-experiment_start
const Features = {
  enableClickmeVacanciesShufflingInToVacanciesOfTheDayMainPage: 'enable_clickme_vacancies_shuffling_in_to_vacancies_of_the_day_main_page'
};
// tempexp_clickme-vacancy-of-the-day-experiment_end

const useFetchVacanciesOfTheDay = () => {
  // tempexp_clickme-vacancy-of-the-day-experiment_start
  const dispatch = useDispatch();
  const isHH = useIsHhru();
  const isVacanciesOfTheDayFetchedRef = useRef(false);
  const vacanciesOfTheDay = useSelector(state => state.vacanciesOfTheDay);
  // tempexp_clickme-vacancy-of-the-day-experiment_start
  const VODShufflingValue = useSelector(_ref => {
    let {
      features
    } = _ref;
    return features[Features.enableClickmeVacanciesShufflingInToVacanciesOfTheDayMainPage];
  });
  const isReplaceClickmeVacancyExp = useExperiment('replace_clickme_vacancy_fixed', Boolean(vacanciesOfTheDay.clickmeVacancies.length));
  // tempexp_clickme-vacancy-of-the-day-experiment_end

  useEffect(() => {
    if (isVacanciesOfTheDayFetchedRef.current) {
      return;
    }
    isVacanciesOfTheDayFetchedRef.current = true;
    dispatch(vacanciesOfTheDayAction(_objectSpread(_objectSpread({}, vacanciesOfTheDay), {}, {
      isLoading: true
    })));
    void fetcher.get(`/shards/vacancies_of_the_day`)
    // tempexp_clickme-vacancy-of-the-day-experiment_start
    .then(data => {
      const {
          vacancies: downloadedVacancies
        } = data,
        downloadedVODData = _objectWithoutProperties(data, _excluded);
      const firstFakeVacancyIndex = calcFirstFakeVacancyIndex(downloadedVacancies !== null && downloadedVacancies !== void 0 ? downloadedVacancies : EMPTY_ARRAY);
      const vacanciesCountToDownload = MAX_VOD_VACANCIES_COUNT - firstFakeVacancyIndex;
      const setVacanciesOfTheDayWithoutClickme = () => dispatch(vacanciesOfTheDayAction(_objectSpread(_objectSpread(_objectSpread({}, vacanciesOfTheDay), downloadedVODData), {}, {
        vacancies: downloadedVacancies,
        readyToShuffleVacancies: false,
        isLoading: false,
        success: true
      })));
      if (downloadedVacancies && vacanciesCountToDownload && isHH) {
        void getClickmeVacancies(_objectSpread(_objectSpread({}, downloadedVODData), {}, {
          vacancies: downloadedVacancies
        }), VODShufflingValue, vacanciesCountToDownload).then(clickmeVacancies => dispatch(vacanciesOfTheDayAction(_objectSpread(_objectSpread(_objectSpread({}, vacanciesOfTheDay), downloadedVODData), {}, {
          tempVacancies: downloadedVacancies,
          clickmeVacancies,
          firstFakeVacancyIndex,
          readyToShuffleVacancies: true
        })))).catch(setVacanciesOfTheDayWithoutClickme);
      } else {
        setVacanciesOfTheDayWithoutClickme();
      }
    }).catch(() => {
      dispatch(vacanciesOfTheDayAction(_objectSpread(_objectSpread({}, vacanciesOfTheDay), {}, {
        readyToShuffleVacancies: false,
        isLoading: false,
        success: false
      })));
    });
  }, [VODShufflingValue, dispatch, isHH, isReplaceClickmeVacancyExp, vacanciesOfTheDay]);
  useLayoutEffect(() => {
    if (vacanciesOfTheDay.readyToShuffleVacancies) {
      dispatch(vacanciesOfTheDayAction(_objectSpread(_objectSpread({}, vacanciesOfTheDay), {}, {
        vacancies: shuffleVacanciesOfTheDayWithClickme(vacanciesOfTheDay.tempVacancies, vacanciesOfTheDay.clickmeVacancies, vacanciesOfTheDay.firstFakeVacancyIndex, isReplaceClickmeVacancyExp),
        readyToShuffleVacancies: false,
        isLoading: false,
        success: true
      })));
    }
  }, [dispatch, isReplaceClickmeVacancyExp, vacanciesOfTheDay]);
};
// tempexp_clickme-vacancy-of-the-day-experiment_end

export default useFetchVacanciesOfTheDay;